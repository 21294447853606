.Policies {
    button {
        margin-right: 10px;
    }
    label {
        font-size: 1rem;
    }
    .search-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 2.5rem;
        @media (max-width: 768px) {
            justify-content: center;
            flex-wrap: wrap;
        }
    }
    .search-input {
        margin-right: 1rem;
        min-width: 21rem;
        @media (max-width: 768px) {
           min-width: auto;
        }
    }
    .paginationContainer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .paginationArrow {
        padding: 5px;
        cursor: pointer;
    }
}
