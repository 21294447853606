.PendingQuotes {
  .search-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2.5rem;
    @media (max-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .search-input {
    margin-right: 1rem;
    min-width: 21rem;
    @media (max-width: 768px) {
      min-width: auto;
    }
  }
  .controls-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 31.25rem;
  }
  .search-input {
    flex: 1;
    margin-right: 1rem;
    color: #000;
  }
}
